#bodycontent, #header,  #link_to_eng_rus_version, #link_to_rus_version, #mainbar,  #advertisement1, #advertisement2, #FAQ_russian,
#workingmode_phonenumber, #languagebar, #news_of_betarill,  #userbar, #footer, #ad_left,  #ad_left1, #ad_left2,  #ad_left3, #ad_right, #ad_right1, #ad_right2, #ad_right3,  #betarill_logo_place, #slogan,
span.footer_mainbar {
    position: absolute;
}

body {
    background-color: #110691;
    background-size: cover;
    background-attachment: fixed;
}

label {
    font-size: 90%;
}

footer {
    background-color: #0000FF !important;
    font-family: Arial;
    font-size: 18px;
    padding-top: 10px;
    padding-bottom: 5px;
}

#header {
    top: 10%;
    left: 1%;
    width: 78%;
    height: 30%;
    line-height: 1%;
    font-size: 20px;
}

#header h1 {
    top:1%;
    padding-left: 3%;
    font-size: 250%;
    font-family: Helvetica, Arial, sans-serif;
    font-style: italic;
    color: yellow;
    line-height: 65%;
}

span.slogan {
    font-size: 50%;
    color: white;
    font-family: Helvetica, Arial, sans-serif;
}

progress::-moz-progress-bar .progress-danger{
    background: red;
}

progress::-webkit-progress-value .progress-danger{
    background: red;
}

progress::-moz-progress-bar .progress-warning{
    background: orange;
}

progress::-webkit-progress-value .progress-warning{
    background: orange;
}

progress::-moz-progress-bar .progress-info{
    background: cyan;
}

progress::-webkit-progress-value .progress-info{
    background: cyan;
}

progress::-moz-progress-bar .progress-success{
    background: green;
}

progress::-webkit-progress-value .progress-success{
    background: green;
}

#bodycontent {
    left: 2%;
    top: 8%;
    font-size: 18px;
    font-family: Arial;
    background-color: #F0FFF0;
    line-height: 140%;
}

#bodycontent h2 {
    text-align: center;
    color: #660099;
}

#bodycontent h2 {
    background-color: #CEECF5;
    line-height: 150%;
    font-style: italic;
}

#bodycontent h3 {
    background-color: #BFEFFF;
    line-height: 120%;
}

#bodycontent h4 {
    text-align: center !important;
    color: #660099;
}

#bodycontent p {
    padding-left: 2%;
}

#adcontent {
    font-family: "Trebuchet MS", Helvetica, Arial, sans-serif;
    height: 220%;
    line-height: 200%;
}

#ad_right {
    font-family: "Trebuchet MS", Helvetica, Arial, sans-serif;
    height: 220%;
    line-height: 200%;
    top: 15%;
    right: 1%;
    font-size: 20px;
    width: 18%;
}

.role8{
    background-color: orange !important;
}

.role9{
    background-color: #FFDF00 !important;
}

.role10{
    background-color: #F0E68C !important;
}

#slogan {
    top: 34%;
    font-size: 140%;
    left: 25%;
    font-weight: bold;
    font-family: Helvetica, Arial, sans-serif;
    font-style: italic;
}

#betarill_logo_place {
    top: 12%;
    left: 19.5%;
    width: 60.9%;
    height: 22%;
}

#mainbar {
    color: grey;
    top: 2%;
    width:100%;
    padding-left: 0;
    font-family: Trebuchet MS, Helvetica, sans-serif;
    border-radius: 5px;
    z-index: 2;
}

#mainbar th {
    border: 3px solid purple;
    font-size: 110%;
    border-radius: 5px;
}

#left-panel{
    background-size: 100%;
    min-height: 400px;
}

#right-panel{
    background-size: 100%;
    min-height: 400px;
}

#news_of_betarill {
    top: 16%;
    left: 4%;
    font-size: 30px;
    font-family: "Trebuchet MS", Helvetica, Arial, sans-serif;
    text-align: center;
}

#languagebar {
    top: 10%;
    left: -10%;
    font-size: 30px;
    font-family: "Trebuchet MS", Helvetica, Arial, sans-serif;
    text-align: center;
}

#userbar {
    top: 5%;
    right: 25%;
    font-size: 20px;
    font-family: "Trebuchet MS", Helvetica, Arial, sans-serif;
    text-align: center;
    background-color: #B0E2FF;
    border-radius: 5px;
}

#userbar2 {
    top: 5%;
    font-size: 20px;
    font-family: "Trebuchet MS", Helvetica, Arial, sans-serif;
    text-align: center;
    background-color: #B0E2FF;
    border-radius: 5px;
}

a {
    font-family: Verdana, Geneva, sans-serif;
}

a:link {
    color: #9932CC;
    text-decoration: none;
}

a:visited {
    color: #9932CC;
}

a:hover {
    color: blue;
    background-color: #CECECE;
    border-radius: 5px;
}

.dropdown .dropdown-menu a:hover {
    background-color: #CECECE;
}

a:active {
    color: #9932CC;
}

#footer {
    left: 0;
    position: fixed;
    bottom: 0;
    color: #585858;
    font-size: 16px;
    background-color: #F5A9BC;
    width: 100%;
    z-index: 2;
}

.logo {
    padding: 0 !important;
}

.row-eq-height {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.text-normal {
    color:white !important;
}

.text-yellow {
    color:yellow !important;
    font-family: Arial, Helvetica serif;
}

.text-link {
    color:purple !important;
}

.text-center {
    text-align: center !important;
}

.text-xxs {
    font-size: 14px !important;
}

.text-xs {
    font-size: 16px !important;
}

.text-sm {
    font-size: 18px !important;
}

.text-md {
    font-size: 20px !important;
}

.text-lg {
    font-size: 24px !important;
}

.text-xl {
    font-size: 28px !important;
}

.text.xxl{
    font-size: 32px !important;
}

.details {
    margin-bottom: 10px;
}

.multistepform fieldset:not(:first-of-type) {
    display: none;
}

.carousel-container {
    background-color: #212529;
    height: 5em;
}

.carousel-caption-bottom {
    max-width: 100%;
    width:100%;
    left: 0;
    right: 0;
    bottom: 0;
    padding-bottom: 1px;
}

@media only screen and (max-width :  576px){
    .bradsense{
        display: inline-block;
        margin-top: 5px;
        width: 480px;
        height: 90px;
    }

    #bodycontent{
        width: 96%;
    }
}

@media only screen and (min-width :  576px){
    .bradsense{
        display: inline-block;
        margin-top: 10px;
        width: 560px;
        height: 100px;
    }

    #bodycontent{
        width: 96%;
    }
}

@media only screen and (min-width :  768px){
    .bradsense{
        display: inline-block;
        margin-left: 10px;
        width: 120px;
        height: 600px;
    }

    #bodycontent{
        width: 80%;
    }
}

@media only screen and (min-width :  992px){
    .bradsense{
        display: inline-block;
        margin-left: 15px;
        width: 150px;
        height: 600px;
    }

    #bodycontent{
        width: 80%;
    }
}

@media only screen and (min-width :  1200px){
    .bradsense{
        display: inline-block;
        margin-left: 20px;
        width: 200px;
        height: 600px;
    }
}

@media only screen and (max-width : 992px) {
    #bodycontent {
        left: 1%;
        right: 120px;
    }

    nav{
        margin-bottom:10px;
    }

    h2 {
        font-size: 20px;
    }

    .alert {
        font-size: 14px;
    }

    .table-font {
        font-size:12px;
    }
}